<script>
    // import Textfield from '@smui/textfield';
    // import Button, { Label } from '@smui/button';
    import Icon from '@smui/textfield/icon';
    import HelperText from '@smui/textfield/helper-text';
    import { TextField, Button } from 'svelte-materialify/src';
    // import { RevoGrid } from "@revolist/svelte-datagrid";
    import ScenarioBuilder from "../components/ScenarioBuilder.svelte";
    import { fipsToStateCode } from '../../../common/constants';
	// import { defineCustomElements } from "@revolist/revogrid/loader";

    /*
        Idea:
        - user enters data
        - should allow excel-like formula input into user fields
        - can run different scenarios -> duplicate (differences get highlighted)
        - graph generated at the bottom
        - expands to more itemized/advanced calculator
        - saves to marker
        - has to work well on cell phone

        fields:
        - address / auto-fill / save
        
        - purchase price
        - downpayment
        - purchase fees (scale by price)
        - interest (auto-fills)
        - advanced: change loan type (should give brief overview of each)

        - rehab budget
        - advanced: itemize budget

        future improvements:
        - gross income itemization
          - pop-up grid
        - better loan terms itemization
        - automatic rent estimate (and other location-based recommendations)
        - automatic analytics by location/address
        - better extrapolation
          - scenario comparison (already exists)
          - annual extrapolation
     */

    let address = ''
    let purchasePrice = 0
    let downpayment = 20
    let purchaseFee = 0
    let rehabAmount = 0
    let interest = 0 // fetch today's rate
    let income = 0

    let states = {}
    Object.keys(fipsToStateCode).forEach((fips) => {
        states[fipsToStateCode[fips]] = fips
    })

    function onEdit(e) {
	    console.log(e);
	}

    const reformat = (taxRate, insuranceRate, vacancyRate, repairRate) => { return {
        'Purchase Price': {
            format: { unit: '$' },
            tooltip: 'Purchase price of the property.'
        },
        'Downpayment': {
            default: 20,
            format: { unit: '%' },
            tooltip: 'Downpayment as percentage of the purchase price (if paying cash, use 100%). Expand to see more lender parameters.'
        },
        'Years to Repay': {
            default: 30,
            parent: 'Downpayment',
            format: { unit: 'y' },
            tooltip: 'Loan amortization in years (typical is 15 or 30 years for conventional loans). ARM-type loans are typically based on 30-year amortization) for the first 5-7 years. Commercial loans typically have 20-25 year amortization with 5-year balloon.'
        },
        'Interest': {
            default: 7.5,
            parent: 'Downpayment',
            format: { unit: '%' },
            tooltip: 'Interest rate of the loan (assumes fixed interest for the duration of the loan).'
        },
        'Purchase Fees': {
            format: { unit: '$' },
            tooltip: 'Closing fees for the loan, including title insurance, appraisal and origination fees.'
        },
        'Rehab Budget': {
            format: { unit: '$' },
            tooltip: 'Additional cost to bring the property to rentable/sellable condition post-purchase.'
        },

        'Gross Income': {
            color: 'plus-5',
            format: { unit: '$' },
            tooltip: 'Gross monthly income generated by this property (or that will be generated post-rehab).'
        },

        'Expenses': {
            color: 'minus-5',
            format: { unit: '$' },
            formula: (r) => {
                return (
                    r('Mortgage') +
                    r('Property Tax') +
                    r('Insurance') +
                    r('Property Mgmt') +
                    r('HOA Fees') +
                    r('Utilities') +
                    r('Vacancy') +
                    r('Repair Budget')
                )
            },
            tooltip: 'Gross monthly expenses (we picked sane defaults for you based on our experience, but you should adjust these based on your own estimate). Expand to itemize the expenses.'
        },
        'Mortgage': {
            parent: 'Expenses',
            color: 'minus-4',
            format: { unit: '$' },
            formula: (r) => {
                // compute PNI formula for monthly mortgage payment
                const p = r('Purchase Price') * (1 - r('Downpayment') / 100)
                const n = 12 * r('Years to Repay')
                const i = r('Interest') / 100 / 12
                return p * i * Math.pow(1 + i, n) / (Math.pow(1 + i, n) - 1)
            },
            tooltip: 'Monthly mortgage payment.'
        },
        'Property Tax': {
            parent: 'Expenses',
            color: 'minus-4',
            format: { unit: '$' },
            formula: (r) => {
                return taxRate * r('Purchase Price') / 12
            },
            tooltip: 'Monthly property tax (take last reported tax figure from property records and divide by 12), if your taxes are escrowed, you can update mortgage payment to reflect that and set this value to zero.'
        },
        'Insurance': {
            parent: 'Expenses',
            color: 'minus-4',
            format: { unit: '$' },
            formula: (r) => {
                return insuranceRate * r('Purchase Price') / 12
            },
            tooltip: 'Monthly insurance payment (you can request a quote from local carrier), if your taxes are escrowed, you can update mortgage payment to reflect that and set this value to zero.'
        },
        'Vacancy': {
            parent: 'Expenses',
            color: 'minus-4',
            format: { unit: '$' },
            formula: (r) => {
                return r('Gross Income') * vacancyRate
            },
            tooltip: 'Buildings typically see 5-10% vacancy depending on the area, time spent rehabing the unit or finding new tenant counts towards vacancy.'
        },
        'Repair Budget': {
            parent: 'Expenses',
            color: 'minus-4',
            format: { unit: '$' },
            formula: (r) => {
                return r('Gross Income') * repairRate
            },
            tooltip: 'You should budget 5-10% of your income for repairs, every part of the building (roof, furnace, plumbing, windows, etc.) has a lifespan and will need to be repaired eventually. Failing to account for this may leave you in the negative.'
        },
        'Utilities': {
            parent: 'Expenses',
            color: 'minus-4',
            format: { unit: '$' },
            tooltip: 'Common area utilities and utilities paid by landlord (water & sewer, gas, electrical, etc.).'
        },
        'HOA Fees': {
            parent: 'Expenses',
            color: 'minus-4',
            format: { unit: '$' },
            tooltip: 'Home-owner assosciation fees, if the property is part of an HOA (common for condos and houses in certain communities).'
        },
        'Property Mgmt': {
            parent: 'Expenses',
            color: 'minus-4',
            format: { unit: '$' },
            tooltip: 'Property management fees, if you\'re not self-managing. You may want to budget these in if you\'re buying out-of-state or plan to scale beyond a couple buildings.'
        },

        'Rent / Price': {
            format: {
                gradient: {
                    'minus-5': 0.1, 'minus-4': 0.2, 'minus-3': 0.3, 'minus-2': 0.4, 'minus-1': 0.5,
                    'plus-5': 1.1, 'plus-4': 0.9, 'plus-3': 0.7, 'plus-2': 0.6, 'plus-1': 0.5
                },
                unit: '%',
                readonly: true
            },
            formula: (r, cols) => {
                return 100 * r('Gross Income') / r('Purchase Price')
            },
            readonly: true,
            tooltip: 'Monthly rent as a fraction of purchase price, typically a property with more than 1% rent/price will cash-flow (unless it\'s in high-vacancy/crime area).'
        },
        'Net Income': {
            format: {
                gradient: {
                    'minus-5': -800, 'minus-4': -600, 'minus-3': -400, 'minus-2': -200, 'minus-1': 0,
                    'plus-5': 2000, 'plus-4': 1000, 'plus-3': 500, 'plus-2': 200, 'plus-1': 0
                },
                unit: '$'
            },
            formula: (r) => {
                return r('Gross Income') - r('Expenses')
            },
            readonly: true,
            tooltip: 'Net income after subtracting all expenses, good rule of thumb is at least $200/door.'
        },
        'Cap Rate': {
            format: {
                gradient: {
                    'minus-5': 0.5, 'minus-4': 1, 'minus-3': 2, 'minus-2': 3, 'minus-1': 4,
                    'plus-5': 10, 'plus-4': 8, 'plus-3': 6, 'plus-2': 5, 'plus-1': 4
                },
                unit: '%'
            },
            formula: (r) => {
                return 100 * 12 * (r('Net Income') + r('Mortgage')) /
                    (r('Purchase Price') + r('Purchase Fees') + r('Rehab Budget'))
            },
            readonly: true,
            tooltip: 'Capitalization rate is net operating income divided by asset price (does not factor in cost of the mortgage).'
        },
        'Cash-on-Cash': {
            format: {
                gradient: {
                    'minus-5': -3, 'minus-4': -2, 'minus-3': -1, 'minus-2': 0, 'minus-1': 2,
                    'plus-5': 10, 'plus-4': 8, 'plus-3': 6, 'plus-2': 4, 'plus-1': 2
                },
                unit: '%'
            },
            formula: (r) => {
                return 100 * 12 * r('Net Income') /
                    ((r('Downpayment') * r('Purchase Price') / 100) + r('Purchase Fees') + r('Rehab Budget'))
            },
            readonly: true,
            tooltip: 'Similar to cap rate, but factors in cost of the mortgage. The difference between this rate and cap rate is basically the effect of leverage.'
        },
        'DSCR': {
            format: {
                gradient: {
                    'minus-5': 0.5, 'minus-4': 0.75, 'minus-3': 0.9, 'minus-2': 1, 'minus-1': 1.15,
                    'plus-5': 2, 'plus-4': 1.8, 'plus-3': 1.6, 'plus-2': 1.4, 'plus-1': 1.25
                },
                unit: 'x'
            },
            formula: (r) => {
                return (r('Net Income') + r('Mortgage')) / r('Mortgage')
            },
            readonly: true,
            tooltip: 'Debt service coverage ratio is net operating income divided by mortgage payment (required to be at least 1.25 for most multi-family/commercial lenders).'
        }
    }}
    $: format = reformat(propertyTaxMultiplier, insuranceMultiplier, vacancyMultiplier, repairMultiplier)

    // updates insurance and tax estimates based on address provided
    let propertyTaxMultiplier = 0.01
    let insuranceMultiplier = 0.005
    let vacancyMultiplier = 0.08
    let repairMultiplier = 0.1
    async function updateEstimates() {
        // grab state/zip from address (the address is not curated, it can be anything from a 2-letter state code to complete address)
        let data = {}
        let year = new Date().getFullYear()
        let metricList = [
            // tax estimate
            'government:taxes:property_tax_rate',
            // insurance estimate: state, flood, fire, crime
            'environment:flood:flood_risk',
            'environment:fire:fire_risk',
            'environment:wind:wind_risk',
            'government:crime:crime_index',
            // vacancy
            'housing:vacant'
        ]
        let metrics = metricList.map((m) => `${m}=1`).join('&')
        if (address.length < 2) {
            return // ignore anything under 2 letters
        } else if (address.length === 2 && states[address.toUpperCase()]) {
            // this looks like a state
            data = await fetch(`/api/predict/${year}/info/state/${address}?${metrics}`).then(r => r.json())
            metricList.forEach((m) => {
                data[m] = data[m].filter(a => a).reduce((a, b) => a + b, 0) / data[m].length
            })
        } else if (address.length === 5 && !isNaN(address)) {
            // this looks like a zip code
            data = await fetch(`/api/predict/${year}/info/zip/${address}?${metrics}`).then(r => r.json())
            metricList.forEach((m) => {
                data[m] = data[m].value
            })
        } else if (address.length > 5 && address.includes(' ') && address.includes(',')) {
            // this looks like an address, try to regex for zipcode and fallback to state
            let zip = address.match(/\d{5}/)
            if (zip) {
                data = await fetch(`/api/predict/${year}/info/zip/${zip[0]}?${metrics}`).then(r => r.json())
                metricList.forEach((m) => {
                    data[m] = data[m].value
                })
            } else {
                let state = address.match(/, ([A-Z]{2})/)[1]
                if (states[state]) {
                    data = await fetch(`/api/predict/${year}/info/state/${state}?${metrics}`).then(r => r.json())
                    metricList.forEach((m) => {
                        data[m] = data[m].filter(a => a).reduce((a, b) => a + b, 0) / data[m].length
                    })
                }
            }
        }
        propertyTaxMultiplier = Math.min(data['government:taxes:property_tax_rate'] || 0.01, 0.031)
        vacancyMultiplier = data['housing:vacant'] || 0.08
        insuranceMultiplier = estimateInsurance(data, 0.005)
        repairMultiplier = 0.1 + adjustment(
            insuranceMultiplier,
            { value: 0.002, score: -0.01 },
            { value: 0.01, score: 0.01 }
        )
    }

    const adjustment = (val, min, max) => {
        // return a value between min.score and max.score based on where val falls between min.value and max.value
        return min.score + (max.score - min.score) * (val - min.value) / (max.value - min.value)
    }
    function estimateInsurance(data, baseRate) {
        let multiplier = baseRate
        if (data['government:crime:crime_index']) {
            multiplier += adjustment(
                data['government:crime:crime_index'],
                { value: 0, score: -0.001 },
                { value: 300, score: 0.003 }
            )
            if (data['government:crime:crime_index'] > 300) {
                multiplier += 0.002 // additional penalty for high crime areas
            }
        }
        if (data['environment:fire:fire_risk']) {
            multiplier += adjustment(
                data['environment:fire:fire_risk'],
                { value: 0, score: -0.001 },
                { value: 10, score: 0.004 }
            )
            if (data['environment:fire:fire_risk'] > 7) {
                multiplier += 0.001 // additional penalty for high fire risk areas
            }
        }
        if (data['environment:wind:wind_risk']) {
            multiplier += adjustment(
                data['environment:wind:wind_risk'],
                { value: 0, score: -0.001 },
                { value: 300, score: 0.002 }
            )
            if (data['environment:wind:wind_risk'] > 250) {
                multiplier += 0.001 // additional penalty for high wind risk areas
            }
        }
        if (data['environment:flood:flood_risk']) {
            multiplier += adjustment(
                data['environment:flood:flood_risk'],
                { value: 0, score: -0.001 },
                { value: 300, score: 0.003 }
            )
            if (data['environment:flood:flood_risk'] > 200) {
                multiplier += 0.003 // additional penalty for high flood risk areas
            }
        }
        return multiplier
    }
</script>

<div class="container">
    <h1>Napkin Analysis</h1>
    <!-- {#key propertyTaxMultiplier} -->
        <ScenarioBuilder {format} />
    <!-- {/key} -->
</div>
<div class="hint">
    If you put in the address or state below, the calculator will adjust property tax and insurance estimates to your location.
</div>
<div class="save-controls">
    <TextField bind:value={address} on:input={updateEstimates}>Address</TextField>
    {#if globalThis.user}
        <Button class="primary-color">Save</Button>
    {:else}
        <div class="login-to-save">
            Login to save your analysis or load existing one.
        </div>
    {/if}
</div>

<style type="text/scss">
    .container {
        height: 70vh;
        width: 100%;
        margin: 32px 64px;

        h1 {
            text-transform: uppercase;
        }

        :global(.long-text) {
            width: 500px;
        }
    }

    .hint {
        padding: 30px;
        color: #666;
        font-size: 0.9rem;
    }

    .save-controls {
        position: fixed;
        bottom: 0;
        display: flex;
        padding: 20px;
        width: 100%;
        max-width: 1000px;

        &> :global(*) {
            margin: 10px;
        }

        .login-to-save {
            margin: auto;
            margin-bottom: 30px;
        }
    }

    @media only screen and (max-width: 600px) {
        .container {
            margin: 0px;

            h1 {
                padding: 0 20px;
            }
        }
    }
</style>