<script>
    import {
        Button,
        TextField,
    } from 'svelte-materialify/src';
    import FormField from '@smui/form-field';
    import Radio from '@smui/radio';
    import { Label, Icon } from '@smui/common';
    import Editable from '../components/Editable.svelte';
    import { navigate } from 'svelte-routing';

    let email = globalThis.user ? globalThis.user.email : '';
    function updateUser(field) {
        return (event) => {
            const data = { [field]: event.detail.value }
            return fetch(`/api/user/${globalThis.user.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(r => {
                if (r.status !== 200) {
                    throw new Error(r.statusText)
                }
                return r.json()
            }).then(r => {
                if (r.error) {
                    throw new Error(r.error)
                }
                globalThis.user[field] = event.detail.value
            }).catch(e => {
                event.detail.onError(e)
                // throw e
            })
        }
    }

    function updatePreference(field) {

    }

    let focus = 'USA'
    let granularity = 'county'
    let assetClass = 'sfr'
</script>

<div class="container">
    <h1>Settings</h1>

    <h3>User Defaults</h3>
    <p>These settings control look and feel of the map and Napkin Analysis.</p>
    <div class="row">
        <Icon class="material-icons">map</Icon>
        <Label>Starting Location:</Label>
        <Editable on:submit={updatePreference("focus")} value={focus} />
    </div>
    <div class="row">
        <Icon class="material-icons">grain</Icon>
        <Label>Granularity:</Label>
        {#each ['county', 'zip', 'tract'] as option}
            <FormField>
                <Radio bind:group={granularity} value={option} touch />
                <span class="upper" slot="label">{option}</span>
            </FormField>
        {/each}
    </div>
    <div class="row">
        <Icon class="material-icons">home_work</Icon>
        <Label>Asset Class:</Label>
        {#each ['sfr', 'mfr', 'commercial'] as option}
            <FormField>
                <Radio bind:group={assetClass} value={option} touch />
                <span class="upper" slot="label">{option}</span>
            </FormField>
        {/each}
    </div>

    <h3>Macros</h3>
    <p>This is where you can modify metric combinations that are saved under the "Favorites" section.</p>

    <h3>Private Metrics</h3>
    <p>
        You can upload private data to train our model on, converting a set of datapoints from a spreadsheet
        into a metric that can be plotted/analyzed on the map. We will automatically correlate this data with
        existing metrics to improve the accuracy. The metrics will only be visible to your
        organization (requires Business-tier subscription).
    </p>
    <div class="row">
        <Button text>Learn More about The Format</Button>
        <Button outlined>Upload Data</Button>
    </div>

    <h3>Payment Information</h3>
    <div class="row upper" id="subscription">
        Current Subscription:
        <Button class="primary-text" text on:click={() => navigate('/upgrade')}>{globalThis.user.tier}</Button>
    </div>
    <Button outlined>Update Payment Information</Button>

    <h3>Account Information</h3>
    <Editable on:submit={updateUser("email")} value={email} />
    <Button outlined>Change Password</Button>
</div>

<style type="text/scss">

    .container {
        display: flex;
        max-width: 700px;
        flex-direction: column;
        margin: auto;
        margin-top: 100px;

        #subscription {
            :global(button) {
                margin: 0;
                margin-bottom: 5px;
                font-size: 16px;
            }
        }

        .upper {
            text-transform: uppercase;
        }

        :global(button) {
            margin-top: 10px;
        }
    }
</style>