<script>
    import { createEventDispatcher, onMount } from 'svelte'
    import Tooltip, { Wrapper } from '@smui/tooltip';
  
    export let value, required = true
    export let textarea = false
  
    const dispatch = createEventDispatcher()
    let editing = false, original
  
    onMount(() => {
      original = value
    })
  
    function edit() {
      editing = true
    }
  
    let element
    function submit() {
      if (value !== original) {
          dispatch('submit', { original, value, onSuccess, onError })
      }
      editing = false
    }

    function onSuccess() {
      errorMsg = null
      original = value
      editing = false
    }

    $: errorMsg = null
    function onError(error) {
      // do not reset value
      errorMsg = error
      editing = false
    }
  
    function keydown(event) {
      if (event.key == 'Escape') {
        event.preventDefault()
        value = original
        errorMsg = null
        editing = false
      }
    }
      
      function focus(element) {
          element.focus()
      }
  </script>
  
  {#if editing}
    <form on:submit|preventDefault={submit} on:keydown={keydown}>
      {#if textarea}
        <textarea bind:value on:blur={submit} {required} use:focus cols="80" rows={(value || '').split('\n').length} />
      {:else}
        <input bind:value on:blur={submit} {required} use:focus size={value.length} />
      {/if}
    </form>
  {:else if errorMsg}
    <Wrapper>
      <div class="editable error" on:click={edit}>
        <slot name="value" content={value}>
          {value}
        </slot>
      </div>
      <Tooltip>
        {errorMsg}
      </Tooltip>
    </Wrapper>
  {:else}
    <div class="editable" on:click={edit}>
      <slot name="value" content={value}>
        {value}
      </slot>
    </div>
  {/if}
  
  <style type="text/scss">
    input {
      border: none;
      background: none;
      font-size: inherit;
      color: inherit;
      font-weight: inherit;
      text-align: inherit;
      box-shadow: none;
    }

    .editable {
      cursor: pointer;
      display: inline-block;

      &:hover {
        border: 2px solid #AFE1AF;
        background: rgb(175, 225, 175, 0.3);
        margin: -2px -4px;
        padding: 0 2px;
        border-radius: 5px;

        &:after {
          content: "\e3c9";
          font-family: "Material Icons";
          display: inline-block;
          color: #AFE1AF;
          line-height: 0;
          padding-left: 5px;
        }
      }

      &.error {
        border: 2px solid #E1AFB9;
        background: rgb(225, 175, 185, 0.3);
        margin: -2px -4px;
        padding: 0 2px;
        border-radius: 5px;

        &:after {
          content: "\e000";
          font-family: "Material Icons";
          display: inline-block;
          color: #E1AFB9;
          line-height: 0;
        }
      }
    }
  </style>
  
