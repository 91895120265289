## Format
All metric-related queries are handled by our prediction engine, and have `/api/predict` prefix
in the query. You can not modify the metrics information, so all of the endpoints in this section use the `GET`
method.

Basic query format for informational queries (`info` and `timeline`) is:  
**`/api/predict/:year/:query/:region/:delta?:metric=:weight`**

Colons represent variables that should be substituted to form the query.
Subsequent sections will explain how each part of the query is formed.

These queries return approximate value for a given metric at a certain time/location (i.e. population of Chelsea, MA in 2016).

The other type of query is a heatmap query (`rank` and `timelapse`), the format for these queries is:
**`/api/predict/:year/:query/:region/:granule/:delta?:metric=:weight`**

Note the presence of `granule` in the query.


## Year
A regular query will start with a year (yyyy) or a year range (yyyy-yyyy).

Examples:  
`investomation.com/api/predict/`**`2022`**`/rank/state/CA/zip` (year)  
`investomation.com/api/predict/`**`2010-2022`**`/timelapse/state/CA/zip` (year range)

We track 1-4 decades of data for
each metric, depending on the source (government-provided data such as CENSUS and tax records goes back further
than sales data provided by Redfin, for example). If your query falls within the available dataset, you will
see actual data for the region. If your query falls outside, you will see a prediction (along with a score to identify
its accuracy) based on the predictive model for the query. Each metric uses a separate model of extrapolation, and
we constanstly tweak our models to be more accurate.

## Query
The query controls what kind of result is returned. The **`query`** must be compatible with both, the format
of the `year` and `region` that was passed in. For example, `rank` is only compatible with **year**,
while `timelapse` is only compatible with **year range**, as you probably already noticed from the example above.

Valid queries include:
- **`info`**: Obtain information on a specific region (currently only works with specific zipcode, will be updated in the future to also aggregate info for larger regions).
- **`timeline`**: Similar to info, but returns info for a range of years (good for plotting data).
- **`rank`**: Rank zip codes in a region for a specific year into percentile groups (good for generating maps).
- **`timelapse`**: Similar to rank, but returns ranks for a range of years (good for tracking map changes over time).

Examples:  
`investomation.com/api/predict/2022/`**`info`**`/zip/90210?housing=1`  
`investomation.com/api/predict/2010-2022/`**`timeline`**`/zip/90210?housing=1`  
`investomation.com/api/predict/2022/`**`rank`**`/state/CA/zip?housing=1`  
`investomation.com/api/predict/2010-2022/`**`timelapse`**`/state/CA/zip?housing=1`

`timeline` queries can be thought of as a multi-year versions of `info` queries, just like
`timelapse` queries are multi-year versions of `rank` queries.

## Region
Region is an area of land you want to apply your query to. Your query format will differ depending on the region it's being applied to. The following region formats are supported:

**`/us`**  
Applies query to all of United States.  
Example Usage: `/api/predict/2022/rank`**`/us`**`/county?housing=1`

**`/state/:id`**  
Takes 2-letter abbreviation to identify a specific US state.  
Example Usage: `/api/predict/2022/rank`**`/state/CA`**`/zip?housing=1`

**`/msa/:id`**  
Takes the ID of a given [Metropolitan Statistical Area](https://en.wikipedia.org/wiki/Metropolitan_statistical_area) to identify a specific city. For example, Boston is one such MSA, which includes Cambridge, Newton, and many other Boston suburbs, but does not include Providence or Worcester. The ID we use is specific to Investomation, but you can obtain it by executing `/api/predict/related/msa/:zipOrCity` query to find the MSA your 5-digit zip code or city (i.e. `Boston,MA`) belongs to.  
Example Usage: `/api/predict/2022/rank`**`/msa/e838947088b279fa2d360ca5b7ea6321`**`/zip?housing=1`

**`/csa/:id`**  
Takes the ID of a given [Combined Statistical Area](https://en.wikipedia.org/wiki/Combined_statistical_area) to identify a specific set of closely-connected cities. For example, Greater Boston CSA includes the entirety of Boston MSA, as ell as Providence, Worcester, and portions of New Hampshire. The ID we use is specific to Investomation, but you can obtain it by executing `/api/predict/related/csa/:zipOrCity` query to find the CSA your 5-digit zip code or city (i.e. `Boston,MA`) belongs to.  
Example Usage: `/api/predict/2022/rank`**`/csa/61a809ba35e19767af67bb35f8b91d5f`**`/county?housing=1`

**`/city/:id`**
Takes the ID of a city to identify a specific city. A city is a subset of an MSA, and can be used to identify a specific city within an MSA. In rural areas, cities can be standalone entities, without belonging to an MSA.

**`/county/:id`**  
Takes county FIPS to identify a specific county.
Example Usage: `/api/predict/2022/info`**`/county/25001`**`?housing=1`

**`/zip/:id`**  
Takes the 5-digit zip code to identify a specific postal area.
Example Usage: `/api/predict/2022/info`**`/zip/02465`**`?housing=1`

**`/tract/:id`**  
Takes tract GEOID to identify a specific CENSUS tract. Currently this is the smallest region we support, but we're exploring Uber's [H3](https://www.uber.com/blog/h3/) indexing system to identify more granular regions.  
Example Usage: `/api/predict/2022/info`**`/tract/250010101`**`?housing=1`

**Note:** regions other than `/us` require user registration.

### Region-Seeking Endpoints
Some queries require the user to know internal Investomation ID of a given region (like MSA and CSA). This ID can be determined
via the following query:

**`/api/predict/related/:regionType/:zipOrCity`**  
This query identifies a greater region that a certain 5-digit zip code or city belongs to. City should include state in its name (i.e. `Miami,FL`). Region type can be either `msa` or `csa`. This endpoint will also return all other zip codes that belong to this statistical area.

Examples:  
`investomation.com/api/predict/related/`**`msa/90210`**  
`investomation.com/api/predict/related/`**`csa/Boston,MA`**

### Proximity Analysis
Proximity analysis estimates data within a certain radius of a given location. This could be useful to estimate demand near your target location. For example, in self-storage industry investors often conduct a "demand study" to understand whether the location can reach full occupancy or if there is room to raise rents. This study consists of measuring population and self-storage capacity within a certain radius of the property (typically 3-5 miles). Investomation makes such analysis a breeze. The endpoint for this analysis is:

**`/api/predict/:year/info/proximity?radius=:radius&lat=:lat&lng=:lng&metric=:weight`**
The result will be in the following format:
```
{
  "zipcodeOverlap": { zipcode: overlapPercentage },
  "estimates": { ... metrics in the same format as regular info query ... }
}
```

## Granule
In addition to region, some endpoints take a `:granule` parameter. Granule represents the size of the chunk we'll be dividing our region into. For example, if our region is the city of Denver, and our granule size is a county, the `rank` query will return all counties that are part of the Denver MSA, ranked by the metric we requested. Think of a region like a loaf of bread and granule as the size of each slice.

Valid granule sizes are: **`tract`**, **`zip`**, **`county`**

Example Usage: `/api/predict/2022/rank/us`**`/county`**`?housing=1`

### Granule Context Endpoints
These endpoints are used to find granules of a certain size that are part of a larger region. They are useful when you have a region and want to find all the smaller regions that are part of it.

**`/api/predict/context/granules/:granularity/of/:regionClass/:regionId:`**  
Return a list of granule IDs of requested `granularity` for a given `regionClass` and `regionId`.

Example Usage:  
`/api/predict/context/granules/`**`zip`**`/of/`**`msa/e838947088b279fa2d360ca5b7ea6321`**  
`/api/predict/context/granules/`**`county`**`/of/`**`state/MA`**

**`/api/predict/context/granules/:granularity/of/city/:cityId`**  
Return a list of granule IDs of requested `granularity` for a given `cityId`. This
endpoint works very similar to the endpoint above, it's a convenience method to avoid
having to make a separate query converting city to MSA or CSA.

Example Usage:  
`/api/predict/context/granules/`**`zip`**`/city/`**`Denver,CO`**

**`/api/predict/context/:regionSize/of/:granularity/:granuleId`**  
Return a list of sibling granule IDs of type `granularity` in a given `regionSize` that represents the parent region of `granuleId`. This endpoint is meant for cases when you have granule and want to find other nearby granules but don't know the parent region.

Example Usage:  
`/api/predict/context/`**`msa`**`/of/`**`zip/90210`**

## Delta
Delta parameter is optional, and changes the query to return the proportional difference in change instead of the actual value. The value of **`delta`** corresponds to the number of years back to go to in order to compute the difference. Delta is useful to see which areas are changing faster than others, either for better (higher percentile) or for worse (lower percentile).

Examples:

`/2018/rank/state/FL/zip/`**`delta/4`**`?housing:home_price=1`  
Rank all Florida zip codes by percent growth in home prices between 2014 and 2018 (delta of 4 years).

`/2010-2018/timelapse/state/FL/zip/`**`delta/4`**`?housing:home_price=1`  
Similar to the first query, but perform such ranking for every year between 2010 and 2018. 2010 ranks correspond to percent growth between 2006-2010, 2011 correspond to 2007-2011, etc.

## Result
Result format depends on the query, as well as the metrics being requested. See examples below.

### Single Granule
Queries of type `info` and `timeline` return actual data for a specific metric at a specific location/region. Regular `rank`-type metrics will return information as a single data point.

Example return value for `info` query (for obtaining data for specific year):
```
{
  "city": "Newbury",
  "county": "Essex",
  "housing:home_price": {
    "score": 0.7,
    "value": 555309
  }
}
```

Example return value for `timeline` query (for obtaining data change over time):
```
{
  "housing:home_price": [
    {
      "score": 0.95,
      "value": 543388
    },
    {
      "score": 0.7833333333333333,
      "value": 549751
    },
    {
      "score": 0.7,
      "value": 555309
    }
  ]
}
```

Category-based metrics are more complex and aggregate multiple rank-type metrics into one. Both `info`
and `timeline` queries for these will return a list of data for all `rank`-type subqueries
that form the aggregated `categorical`-type query.

Example return value for a `categorical`-type `info` query:
```
{
  "city": "Salisbury",
  "county": "Essex",
  "housing:by_type": {
    "single_family": {
      "score": 0.7,
      "value": 0.5584928949303785
    },
    "condo": {
      "score": 0.7,
      "value": 0.06890226386044895
    },
    "duplex": {
      "score": 0.7,
      "value": 0.05908248442100673
    },
    "triplex": {
      "score": 0.7,
      "value": 0.06792608034936377
    },
    "small_apartment_building": {
      "score": 0.7,
      "value": 0.0711739756497468
    },
    "medium_apartment_building": {
      "score": 0.7,
      "value": 0.020938567929689123
    },
    "large_apartment_building": {
      "score": 0.7,
      "value": 0.15179544836954317
    },
    "mobile_home": {
      "score": 0.7,
      "value": 0.1197719972322517
    },
    "boat": {
      "score": 0.7,
      "value": null
    }
  }
}
```

The [Metrics](#metrics) section will list the type of each metric you can retrieve.

### Granule Ranks
Queries of type `rank` (not to be confused with **metrics** of type `rank`)
will break down all zip codes of a specific region into buckets. These buckets correspond to percentile scores
for regular **`rank`**-type metrics, and categories for **`categorical`**-type
metrics. The winning category for each zip code is also based on percentile scores. This means that a zip code may
be marked with a winning category that is still considered a minority for that zip code relative to other categories if
this zip code corresponds to the most frequent occurrence of that category.

For example, `rank` version of the above `categorical`-type query for `housing:by_type`
metric may mark a zip code as a `mobile_home` hotspot even if the proportion of mobile homes in that zip code
is lower than that of single-family homes, simply because it represents the highest proportion of mobile homes in the area
compared to all other zip codes. This is intentional, to prevent minority metrics from being drowned by the dominant metric,
and allows one to quickly find hotspots even for rare categories.

Example format of a response to the `rank`-type rank query:
```
{
    "type": "ranks",
    "data": {
        "1": [ // 0th percentile
            42023,
            42083,
            42021,
            42053,
            42121,
            42047,
            42107
        ],
        "2": [ // 10th percentile
            42123,
            42033,
            42105,
            42087,
            42065,
            42111,
            42051
        ],
        "3": [ // 20th percentile
            42097,
            42073,
            42085,
            42039,
            42079,
            42005,
            42049
        ],
        "4": [ // 30th percentile
            42013,
            42031,
            42063,
            42025,
            42059,
            42061,
            42069
        ],
        "5": [ // 40th percentile
            42007,
            42009,
            42035,
            42067,
            42015,
            42117,
            42129
        ],
        "6": [ // 50th percentile
            42037,
            42113,
            42043,
            42057,
            42089,
            42081,
            42133
        ],
        "7": [ // 60th percentile
            42011,
            42075,
            42131,
            42099,
            42103,
            42003,
            42109
        ],
        "8": [ // 70th percentile
            42115,
            42093,
            42055,
            42125,
            42127,
            42001,
            42077
        ],
        "9": [ // 80th percentile
            42119,
            42095,
            42041,
            42071,
            42101,
            42019,
            42045
        ],
        "10": [ // 90th percentile
            42027,
            42091,
            42017,
            42029
        ]
    }
}
```

Example format of a response to the `categorical`-type rank query:
```
{
    "type": "categories",
    "data": {
        "no_data": [
            1063,
            1086,
            1199,
            1937,
            2366,
            2584,
            2643
        ],
        "gas_heat": [
            1001,
            1003,
            1022,
            ...
            2780,
            2790,
            2791
        ],
        "gastank_heat": [
            1026,
            1029,
            1032,
            ...
            2568,
            2575,
            2652
        ],
        "electric_heat": [
            1002,
            1013,
            1020,
            ...
            2760,
            2762,
            2763
        ],
        "oil_heat": [
            1005,
            1007,
            1008,
            ...
            2770,
            2771,
            2779
        ],
        "coal_heat": [
            1068,
            1081,
            1256
        ],
        "wood_heat": [
            1070,
            1083,
            1098,
            ...
            1378,
            1379,
            1436
        ],
        "solar_heat": [
            1257,
            1338
        ],
        "other_heat": [
            1079,
            1080,
            1224,
            ...
            1467,
            1516,
            1521
        ],
        "no_heat": [
            2203,
            2457
        ]
    },
    "categories": [
        "gas_heat",
        "gastank_heat",
        "electric_heat",
        "oil_heat",
        "coal_heat",
        "wood_heat",
        "solar_heat",
        "other_heat",
        "no_heat"
    ]
}
```

<a name="versus-mode"></a>

### Versus Mode
When number of selected categories is two (either because there are only two categories in a given metric - i.e. Democrat vs
Republican) or because you narrowed the selection down to two, the result will report more granular differences between the
two categories in each zipcode rather than simply color-coding the zipcode according to the winning category. This is called
`versus mode` and more information on it can be found on our [blog](https://investomation.com/blog/improved-category-visuals). To learn how to activate `versus mode` on demand, see [Metric Filters](#metric-filters) section.

Example format of a response to the `versus`-type rank query:
```
{
    "type": "versus",
    "data": {
        "0": [ // neutral
            15054,
            15779,
            16121,
            ...
            18248,
            19523,
            19550
        ],
        "1": [ // low lean towards 1st category
            15640,
            16415,
            16444,
            ...
            18045,
            18063,
            18091
        ],
        "2": [
            15316,
            15611,
            16401,
            ...
            18088,
            18343,
            18351
        ],
        "3": [
            15006,
            15015,
            15017,
            ...
            19457,
            19460,
            19520
        ],
        "4": [
            15018,
            15024,
            15025,
            ...
            19475,
            19477,
            19492
        ],
        "5": [
            15030,
            15045,
            15075,
            ...
            19473,
            19474,
            19525
        ],
        "6": [
            15014,
            15031,
            15034,
            ...
            19090,
            19310,
            19401
        ],
        "7": [
            15213,
            15612,
            16427,
            ...
            19113,
            19367,
            19405
        ],
        "8": [
            16677,
            19103,
            19106,
            ...
            19147,
            19150,
            19154
        ],
        "9": [
            15007,
            19102,
            19104,
            ...
            19151,
            19152,
            19153
        ],
        "10": [], // high lean towards 1st category
        "no_data": [],
        "-10": [], // high lean towards 2nd category
        "-9": [],
        "-8": [
            15325,
            15334,
            15357,
            ...
            17229,
            17238,
            17267
        ],
        "-7": [
            15320,
            15322,
            15337,
            ...
            18850,
            18851,
            18854
        ],
        "-6": [
            15310,
            15327,
            15380,
            ...
            18832,
            18848,
            18853
        ],
        "-5": [
            15004,
            15021,
            15038,
            ...
            18840,
            18844,
            19549
        ],
        "-4": [
            15012,
            15019,
            15060,
            ...
            19545,
            19554,
            19564
        ],
        "-3": [
            15001,
            15003,
            15005,
            ...
            19608,
            19609,
            19610
        ],
        "-2": [
            15033,
            15061,
            15066,
            ...
            19503,
            19559,
            19560
        ],
        "-1": [ // low lean towards 2nd category
            15410,
            15420,
            15434,
            ...
            19602,
            19604,
            19611
        ]
    },
    "categories": [
        "democrat", // category 1
        "republican" // category 2
    ]
}
```

<a name="metrics"></a>  

## Metrics

Metrics are the bread and butter of Investomation. They're used to rank regions, estimate trends, or
obtain specific information about a given region. You can find a list of all available metrics and their
format by executing the following query:
**`/api/predict/metrics`**

You can combine multiple metrics into a single query via `&` operator, effectively ranking zip codes in 
a certain region based on how well each one fits all of the specified criteria, the same way
[Investomation Heat Map](https://investomation.com/map) combines these metrics.

For example, the following query ranks all zipcodes in Massachusetts by their rent-to-price ratio and
crime, preferring areas with highest rent-to-price ratio and lowest crime:  
`/api/predict/2022/rank/state/MA?housing:rent_to_price=2&crime:crime_index=-1`

Note the weights, negative values correspond to metric being undesirable, and higher weights signify
the importance of the metric. The above query puts twice as much emphasis on rent-to-price ratio as it
does on crime score.

### Metric Types
There are currently 4 types of metrics:
- **`rank`**: regular metrics that rank a region based on its percentile score, these types of metrics can be stacked in your analysis.
- **`proximity`**: proximity metrics rank a location based on its proximity to a given resource and quality of said resource. For example, how far away is the area from nearest school or police station, and what is the score of that police station. Like `rank`-type metrics, these metrics can be stacked as additional overlays.
- **`categorical`**: categorical metrics compare several qualitative metrics based on proportion, due to how this comparison is done, categrical metrics can't stack with other metrics
- **`categorical rank`**: these work like categorical metrics (and can't be stacked), with the exception that categories can be objectively ranked (i.e. number of bedrooms).

<a name="metric-filters"></a>

### Metric Filters
The concept of metric filters is explained on our [blog](https://investomation.com/blog/filtering-metrics). 
You can control these filters via API. For example, for `rank`-type metrics, you can filter our a portion of results that don't
match certain criteria. You can apply a `rank`-type filter by prefixing a `rank`-type metric with the keyword **`filter`**.

For example, the following query will rank all of US zipcodes by highest population, but exclude regions where median home price is below $50,000:  
`/api/predict/2023/rank/us/zip?population:total=1&`**`filter:housing:home_price=below:50000`**

For `categorical`-type metrics, the filter works differently (see above blog article for more detail). Instead of using
the `filter` keyword in the query, the weight (which typically doesn't matter for categorical metrics since they can't be
combined with other metrics) can be replaced with a comma-separated list of categories you want to compare:  
`/api/predict/2023/rank/state/ma/zip?`**`housing:by_heat=0,3`**

Category numbers correspond to the order they are listed in when executing `/api/predict/metrics` query.

**NOTE:** When the number of requested categories is two, as in the above example, the result will be reported in [Versus Mode](#versus-mode) format

### Delta Metrics
Delta metrics are special metrics with an individual `delta` parameter applied to it, independent of other metrics.
Delta metrics use **`delta:#`** suffix. For example, delta version of the `population:total` metric going 5 years back would be called
`population:total:delta:5` and represents population growth over the last 5 years:
`/api/predict/2023/rank/us/zip?housing:units=1&`**`population:total:delta:5=1`**

**NOTE:** Delta metrics don't stack with delta queries, you can't pass `delta` version of a metric as a parameter to `delta` version of a query.

## List of Metrics
The following metrics are available:
