<script>
    /*
    Generates a search field with auto-suggestion box as the user types. Search suggestion sources can be
    independently added to the system.

    Uses 'search' pubsub channel to receive the following events:
    - add.category
    Generates events:
    - load.city
    - load.zipcode
    - load.marker
    - load.geocode
    - load.state
    */
    import { Key } from '../../../../common/constants'
    import pb from '../../../pubsub'
    import { mdiClose, mdiMagnify } from '@mdi/js'
    import { TextField, Icon } from 'svelte-materialify/src'

    let klass = ''
    export {klass as class}
    export let id = '_'
    export let outlined
    export let dense
    export let filled
    export let minChars = 2      // min characters user needs to type to activate search suggestions
    export let entryLimit = 20
    export let direction = 'down'
    const pubsub = pb.setup('Search')

    let searchTerm

    /*
        name: category name/label
        slot2: parsing info for slot2, format: { icon, color, format } (optional)
        searchFn: function for fetching results, format: (term) => Promise<[]> | []
            result entry format: { name, action, slot2? }
        keyword: prefix keyword to set search mode to this context, used with colon
        */
    let categories = {}
    pubsub.subscribe(`${id}:addCategory`, ({ name, slot2, searchFn, keyword, selectFn }) => {
        categories[keyword] = { slot2, searchFn, name, selectFn }
    })
    let categoryList = []
    $: categoryList = Object.keys(categories)

    let keyword
    function findKeyword(term) {
        if (term && term.includes(':')) {
            let kw = term.split(':')[0]
            if (categories[kw]) {
                return kw
            }
        }
        return
    }
    $: keyword = findKeyword(searchTerm)

    let acceptInput = () => {
        fetchedEntries = {}
        onClick(sortedEntries[selectedSuggestionIndex].clickFn)
    };
    pubsub.subscribe(`${id}:acceptInput`, (searchFn) => {
        acceptInput = searchFn;
    })

    let fetchedEntries = {}
    let sortedEntries = []
    const shownEntries = {}
    let selectedSuggestionIndex = -1;           // index within sortedEntries
    let selectedSuggestionCategory = null;      // category currently selected (within shownEntries)
    let selectedSuggestionCategoryIndex = -1;   // index within current category
    function handleKeyDown(event) {
        if (event.keyCode === Key.ENTER) {
            event.preventDefault();
            isFocused = false
            if (selectedSuggestionIndex !== -1) {
                // new search
                // acceptInput(searchTerm).then(() => {
                //     fetchedEntries = {}
                // })
                searchTerm = sortedEntries[selectedSuggestionIndex].clickFn()
            } else if (searchTerm) {
                acceptInput(searchTerm).catch(e => {
                    // inform the user of search failure
                    // searchParams.field.classList.add('not-found');
                    pubSub.publish('notify:error', e.message);
                    pubSub.publish('notify:stopLoad');
                })
            }
            fetchedEntries = {}
        } else if (event.keyCode === Key.ESC) {
            isFocused = false
        } else if (event.keyCode === Key.DOWN) {
            let categories = Object.keys(shownEntries)
            if (!selectedSuggestionCategory) {
                // pick first entry in first category
                selectedSuggestionCategory = categories[0]
                selectedSuggestionIndex = 0
                selectedSuggestionCategoryIndex = 0
            } else if (shownEntries[selectedSuggestionCategory].length > selectedSuggestionCategoryIndex + 1) {
                // pick next entry in same category
                selectedSuggestionCategoryIndex++
                selectedSuggestionIndex = sortedEntries.indexOf(shownEntries[selectedSuggestionCategory][selectedSuggestionCategoryIndex])
            } else if (categories.indexOf(selectedSuggestionCategory) + 1 < categories.length) {
                // end of the list for this category, pick first entry in next category
                selectedSuggestionCategory = categories[categories.indexOf(selectedSuggestionCategory) + 1]
                selectedSuggestionCategoryIndex = 0
                selectedSuggestionIndex = sortedEntries.indexOf(shownEntries[selectedSuggestionCategory][selectedSuggestionCategoryIndex])
            } else {
                // end of the list for all categories, loop around to first entry
                selectedSuggestionCategory = categories[0]
                selectedSuggestionIndex = 0
                selectedSuggestionCategoryIndex = 0
            }
        } else if (event.keyCode === Key.UP) {
            let categories = Object.keys(shownEntries)
            if (!selectedSuggestionCategory) {
                // pick last entry in last category
                selectedSuggestionCategory = categories[categories.length - 1]
                selectedSuggestionIndex = sortedEntries.length - 1
                let category = shownEntries[selectedSuggestionCategory]
                selectedSuggestionCategoryIndex = category.length - 1
            } else if (selectedSuggestionCategoryIndex > 0) {
                // pick previous entry in same category
                selectedSuggestionCategoryIndex--
                selectedSuggestionIndex = sortedEntries.indexOf(shownEntries[selectedSuggestionCategory][selectedSuggestionCategoryIndex])
            } else if (categories.indexOf(selectedSuggestionCategory) > 0) {
                // end of the list for this category, pick last entry in previous category
                selectedSuggestionCategory = categories[categories.indexOf(selectedSuggestionCategory) - 1]
                let category = shownEntries[selectedSuggestionCategory]
                selectedSuggestionCategoryIndex = category.length - 1
                selectedSuggestionIndex = sortedEntries.indexOf(shownEntries[selectedSuggestionCategory][selectedSuggestionCategoryIndex])
            } else {
                // end of the list for all categories, loop around to last entry
                selectedSuggestionCategory = categories[categories.length - 1]
                selectedSuggestionIndex = sortedEntries.length - 1
                let category = shownEntries[selectedSuggestionCategory]
                selectedSuggestionCategoryIndex = category.length - 1
            }
        } else {
            // user starts typing again
            selectedSuggestionIndex = -1;
            fetchedEntries = {}
        }
    }
    let isFocused = false
    const onFocus = () => {
        // select contents
        isFocused = true
    }
    const onBlur = () => isFocused = false
    const onClick = (clickFn) => () => {
        searchTerm = clickFn()
    }

    // converts visual index to internal index, visual index is what the user sees (oganized by categories)
    const getRealIndex = (entry) => {
        return sortedEntries.indexOf(entry)
    }
    // retrieves next internal index for entry that appears next visually (next in same category vs next overall)
    const getNextIndex = (index) => {
        let remainder = index + 1
        let category = null
        for (let catName of Object.keys(shownEntries)) {
            category = shownEntries[catName]
            if (category.length > remainder) {
                break;
            }
            remainder -= category.length
        }
        return sortedEntries.indexOf(category[remainder])
    }
    const isSelected = (item) => {
        if (selectedSuggestionIndex === -1) {
            return false
        }
        return sortedEntries[selectedSuggestionIndex] === item
    }
    // populate new category into search results
    const updateEntries = (category, entries) => {
        fetchedEntries[category] = entries
        const categories = Object.keys(fetchedEntries)
        let entryCount = 0
        sortedEntries = []
        categories.forEach(c => {
            shownEntries[c] = []
            entryCount += fetchedEntries[c].length
        })
        let entriesAdded = 0
        let offset = 0
        let categoryIndex = 0
        while (entriesAdded < entryLimit && entriesAdded < entryCount) {
            const c = categories[categoryIndex]
            const nextEntry = fetchedEntries[c][offset]
            if (nextEntry) {
                shownEntries[c].push(nextEntry)
                sortedEntries.push(nextEntry)
                entriesAdded++
            }
            categoryIndex = (categoryIndex + 1) % categories.length
            if (categoryIndex === 0) {
                offset++
            }
        }
    }
</script>

<div class={klass}>
    <div class="search-bg">
        <TextField
            class="mr-2"
            {outlined}
            {dense}
            {filled}
            clearable
            placeholder="Search"
            bind:value={searchTerm}
            on:keydown={handleKeyDown}
            on:focus={onFocus}
            on:blur={onBlur}
        >
            <div slot="append">
                <Icon path={mdiMagnify} />
            </div>
            <div slot="clear-icon">
                <Icon path={mdiClose} />
            </div>
        </TextField>
    </div>
    {#if isFocused && searchTerm && searchTerm.length >= minChars}
        <div class="relative-wrapper">
            <div class="suggestions {direction}">
                <slot name="search-suggestion-title"></slot>
                {#if keyword}
                    {#await categories[keyword].searchFn(searchTerm.slice(keyword.length + 1).trim())}
                        <!-- render nothing while fetching -->
                    {:then rows}
                        {#if rows && rows.length}
                            {updateEntries(keyword, rows) || ''}
                            <div class="search-category">{categories[keyword].name}</div>
                            {#each shownEntries[keyword] as row}
                                <div
                                    on:mousedown={onClick(row.clickFn)}
                                    on:mouseover={e => row.hoverFn(e)}
                                    class:selected="{selectedSuggestionIndex !== -1 && sortedEntries[selectedSuggestionIndex] === row}"
                                >
                                    <slot name="search-suggestion" {row}>
                                        <div class="search-suggestion">
                                            <span class="query">{row.element.query}</span>
                                        </div>
                                    </slot>
                                </div>
                            {/each}
                        {/if}
                    {/await}
                {:else}
                    {#each categoryList as category (category)}
                        {#await categories[category].searchFn(searchTerm)}
                            <!-- render nothing while fetching -->
                        {:then rows}
                            {#if rows && rows.length}
                                {updateEntries(category, rows) || ''}
                                <div class="search-category">{categories[category].name}</div>
                                {#each shownEntries[category] as row}
                                    <div
                                        on:mousedown={onClick(row.clickFn)}
                                        on:mouseover={e => row.hoverFn(e)}
                                        class:selected="{selectedSuggestionIndex !== -1 && sortedEntries[selectedSuggestionIndex] === row}"
                                    >
                                        <slot name="search-suggestion" {row}>
                                            <div class="search-suggestion">
                                                <span class="query">{row.element.query}</span>
                                            </div>
                                        </slot>
                                    </div>
                                {/each}
                            {/if}
                        {/await}
                    {/each}
                {/if}
            </div>
        </div>
    {/if}
</div>

<style type="text/scss">
    @import 'material-theme';

    :global(#search-area) {
        :global(.s-text-field__wrapper) {
            :global(.s-icon) {
                background: #eee;
            }
            &:hover {
                :global(.s-icon) {
                    background: #ddd;
                }
            }
        }
    }

    .search-bg {
        background: $primary-bg-color;
        overflow: auto;
    }

    .relative-wrapper {
        position: relative;
    }

    .suggestions {
        position: absolute;
        display: inline-block;
        margin: 0;
        padding: 5px;
        background: $primary-bg-color;
        border: 1px solid $secondary-bg-color;
        clear: both;
        z-index: 7;

        &.down {
            top: 0;
        }

        &.up {
            bottom: 40px;
        }

        :global(.search-category) {
            color: $primary-color;
            font-family: 'Lato';
            font-size: 0.65rem;
            padding-top: 5px;
        }

        :global(.material-icons) {
            height: 16px;
            width: 24px;
            min-width: 24px;
            font-size: 16px;
            line-height: 16px;
            vertical-align: text-top;
        }

        :global(.search-suggestion:hover) {
            background: $secondary-bg-color;
            cursor: pointer;
        }

        .selected :global(.search-suggestion) {
            background: $secondary-bg-color;
        }
    }
</style>