<script>
    import { afterUpdate, onMount, tick } from 'svelte'
    import Grid from "./Grid.svelte";
    import pb from "../../pubsub";
    import Button from '@smui/button';
    const pubsub = pb.setup('ScenarioBuilder')

    /*
        Useful for building quick comparison scenarios
        - first column is topic
        - column 2 is basic scenario / control group
        - columns 3+ are extra scenarios
        - columns auto-format / change color
     */

    export let format = {
        'Row Name': {
            default: '20%',
            format: {
                unit: '%'
            }
        },
        'Hidden Row that Expands': {
            parent: 'Row Name',
            format: {
                unit: '$'
            }
        },
        'Auto-computing Row': {
            format: {
                unit: '$',
                fn: (val) => {}
            },
            formula: 'Row Name * 0.2'
        }
    }
    let rows = {}
    let expandable = {}
    Object.keys(format).forEach((k, i) => {
        rows[k] = i
        if (format[k].parent) {
            const parent = format[k].parent
            if (!expandable[parent]) {
                expandable[parent] = { expanded: false, children: [] }
            }
            expandable[parent].children.push(k)
        }
    })
    let columns = [{
        name: '',
        prop: 'name',
        size: 150,
        // cellProperties: ({prop, model, data, column}) => {
        //     return {
        //         style: {
        //             color: 'black'
        //         },
        //         class: {
        //             'title': format[model.name].parent ? false : true,
        //             'expandable': !!expandable[model.name],
        //             'expanded': expandable[model.name] && expandable[model.name].expanded
        //         }
        //     };
        // },
        cellTemplate: (e, props) => {
            return e('div', {
                style: {
                    color: 'black'
                },
                class: {
                    'title': format[props.model.name].parent ? false : true,
                    'expandable': !!expandable[props.model.name],
                    'expanded': expandable[props.model.name] && expandable[props.model.name].expanded
                },
                onmouseover: (e) => {
                    if (format[props.model.name].tooltip) {
                        pubsub.publish('tooltip:show', {
                            element: e.target, message: format[props.model.name].tooltip
                        })
                    }
                }
            }, props.model[props.prop])
        },
        basic: true // do not allow Grid to override this w/ custom cellTemplate
    },
    {
        name: 'Scenario 1',
        prop: 's1',
        cellProperties: ({prop, model, data, column}) => {
            return {
                property: { readonly: true }
            }
        },
    }, {
        name: 'Copy',
        prop: 'addNew',
        columnTemplate: (e, props) => {
            // let color = false
            return e('div', { class: { 'bubble': true, 'button': true } }, props.name)
        },
        basic: true // do not allow Grid to override this w/ custom cellTemplate
    }]

    let grid = []
    Object.keys(format).forEach((rowName, i) => {
        grid.push({
            name: rowName,
            s1: {
                data: format[rowName].default || '',
                format: {
                    color: format[rowName].color,
                    parent: format[rowName].parent,
                    format: format[rowName].format,
                    formula: format[rowName].formula ? (g) => {
                        // we need to pass a row object to scenario formula that wraps around grid object
                        const r = (field) => {
                            return g(rows[field], 's1')
                        }
                        return format[rowName].formula(r, g)
                    } : null
                }
            },
            _hide: !!format[rowName].parent && !expandable[format[rowName].parent].expanded,
            _expandable: !!expandable[rowName],
            _index: i
        })
    })
    $: scenarioCount = 1
    // force re-render on format update (format update happens on address change)
    $: format && (grid = [...grid])

    function addScenario(n = 1) {
        scenarioCount++
        const button = columns.pop()
        const key = `s${scenarioCount}`
        const scenario = { ...columns[n], prop: key, name: `Scenario ${scenarioCount}` }
        columns.push(scenario)
        columns.push(button)

        Object.keys(format).forEach((rowName, i) => {
            grid[i][key] = { ...grid[i][`s${n}`] }
            if (grid[i][key].format) {
                grid[i][key].format = { ...grid[i][`s${n}`].format }
                if (grid[i][key].format.formula) {
                    grid[i][key].format.formula = (g) => {
                        const r = (field) => {
                            return g(rows[field], key)
                        }
                        return format[rowName].formula(r, g)
                    }
                }
            }
        })
        columns = [...columns] // force re-render
    }

    function onHeaderClick(e) {
        if (e.detail && e.detail.prop === 'addNew') {
            // handle `addNew` button
            addScenario()
        }
    }
    function expand(e, a) {
        if (e.detail.prop === 'name') {
            ref.clearFocus() // don't select title cells
            if (expandable[e.detail.val]) {
                if (expandable[e.detail.val].expanded) {
                    // hide
                    expandable[e.detail.val].expanded = false
                    grid.forEach(r => {
                        if (expandable[e.detail.val].children.includes(r.name)) {
                            r._hide = true
                        }
                    })
                } else {
                    // expand
                    expandable[e.detail.val].expanded = true
                    grid.forEach(r => {
                        if (expandable[e.detail.val].children.includes(r.name)) {
                            r._hide = false
                        }
                    })
                }
                grid = [...grid] // force grid re-render
            }
        }

        const cellFormat = format[e.detail.model.name]
        if (cellFormat.readonly) {
            ref.clearFocus()
            const element = child.getCellElement(e.detail)

            // create a selector using data-rgcol=Object.keys(e.detail.model).findIndex(e.detail.prop) and data-rgrow=e.detail.rowIndex
            // const col = Object.keys(e.detail.model).findIndex((k) => k === e.detail.prop)
            // const row = e.detail.rowIndex
            // const element = document.querySelector(`div[data-rgcol="${col}"][data-rgrow="${row}"]`)

            pubsub.publish('tooltip:show', {
                element: element, message: "This cell is read-only."
            })
        }
    }
    let ref;
    let child;

    onMount(async () => {
        // delay loading tooltip logic until everything else has mounted
        await import('../../help')
    })
</script>

<div>
    <Grid {columns} {grid} on:headerclick={onHeaderClick} on:beforecellfocus={expand} bind:this={child} bind:ref={ref} />
</div>

<style type="text/scss">
    div {
        height: 100%;

        :global(.title) {
            font-weight: 700;
            cursor: default;
        }

        :global(.title.expandable) {
            cursor: pointer;
        }

        :global(.title.expandable::after) {
            content: ' +';
        }

        :global(.title.expandable.expanded::after) {
            content: ' -';
        }
    }
    :global(revogr-viewport-scroll .inner-content-table) {
        min-width: unset;
    }
    :global(revogr-header .rgHeaderCell .header-content) {
        flex-grow: unset !important;
    }
    :global(.rgHeaderCell) {
        width: unset !important;
    }
    :global(.rgCell:last) {
        width: unset !important;
    }
    :global(.bubble.button) {
        cursor: pointer;
        background-color: #4caf50;
        vertical-align: middle;
        justify-content: center;
        margin: 0;
        width: 80px !important;
    }
</style>