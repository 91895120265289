<script>
	import { RevoGrid } from "@revolist/svelte-datagrid";
    import { applyPolyfills, defineCustomElements } from '@revolist/revogrid/loader';
    import pb from "../../pubsub";
    const pubsub = pb.setup('Grid')
    
    /*
        General compute grid module
        - can be used as Excel spreadsheet
        - has concept of eval/formula interpolation
        - has concept of per-cell format and per-cell formulas
    */

    // export let source
	export let columns
    // grid is similar to source of revogrid, but with additional metadata, think of "grid"
    // as input and "source" as output compatible w/ revogrid
    // the default value for the grid here is just example usage, it's meant to be overriden
    export let grid = [{
        col1: 'text',
        col2: { format: {}, data: 5 }
    }, {
        col1: 'foo',
        col2: {},
        _hide: true
    }]
    let source = []
    // source should re-render on grid update
    // filter _hide rows and map grid to source format for revogrid in one pass
    $: source = grid.reduce((filtered, r) => {
        // convert each row to output
        if (!r._hide) {
            const row = {}
            Object.keys(r).forEach(c => {
                if (c[0] === '_') {
                    return; // skip meta properties (_hide, etc.)
                }
                // convert each cell to format compatible w/ revogrid
                if (['string', 'number'].includes(typeof r[c])) {
                    // basic cell
                    row[c] = r[c]
                } else {
                    // formatted cell
                    row[c] = r[c].data
                }
            })
            filtered.push(row)
        }
        return filtered;
    }, [])
    export let ref;

    defineCustomElements() // brings the element to life

	function onEdit(e) {

	}

    function evalInContext(js, context) {
        const toString = (obj) => {
            if (typeof obj === 'function') {
                return obj.toString()
            } else {
                return JSON.stringify(obj)
            }
        }

        // return the results of the in-line anonymous function we .call with the passed context
        return function() {
            // allow unquoted column names
            const cols = Object.keys(grid[0]).filter(c => c[0] !== '_').map(c => `const ${c}='${c}'`).join(';')
            // stringify grid data and compute function
            const jsWithContext = cols + `;${Object.keys(context).map(k => `const ${k}=${toString(context[k])}`).join(';')}; ${js}`
            try {
                return eval(jsWithContext);
            } catch (e) {
                return 'ERROR: ' + e.message
            }
        }.call(context);
    }

    function normalize(e) {
        let rowOffset = 0
        for (let row of grid) {
            if (row._hide) {
                rowOffset++
            }
            if (rowOffset + e.detail.rowIndex === row._index) {
                break
            }
        }
        let val = e.detail.model[e.detail.prop]
        val = String(val).replace('$', '').replace('%', '').replace(/,(\d{3})/, '$1')

        // normalize thousands and millions
        if (val) {
            if ('Kk'.includes(val.slice(-1))) {
                // val = parseFloat(val.slice(0, -1)) * 1000
                val = val.replace(/[kK]/g, '*1000')
            }
            if (val && 'Mm'.includes(val.slice(-1))) {
                // val = parseFloat(val.slice(0, -1)) * 1000000
                val = val.replace(/[Mm]{1,2}/g, '*1000000')
            }
            if (val && val[0] !== '=') {
                // val = parseFloat(val)
                val = evalInContext(val, { g: _compute, grid })
            }
        }
        e.detail.model[e.detail.prop] = val
        grid[e.detail.rowIndex + rowOffset][e.detail.prop].data = val
    }

    function onLoseFocus(e) {
        console.log('lose focus', e)
    }

    // returns computed value of a cell given row, col coordinates
    function _compute(row, col) {
        const cell = grid[row][col]
        if (!cell) {
            return 'ERROR: Cell g(' + row + ', ' + col + ') not found, valid rows are 0-' + 
                (grid.length - 1) + ', valid columns: ' + Object.keys(grid[0]).filter(c => c[0] !== '_') + '.'
        }
        const format = cell.format
        let result = NaN
        if (grid[row][col].data !== '') { // prefer manual user entry first
            if (grid[row][col].data[0] === '=') {
                // user formula
                result = evalInContext(grid[row][col].data.slice(1), { g: _compute, grid })
            } else {
                // user value
                result = grid[row][col].data
            }
        } else if (format && format.formula) {
            // if not available, use internal formula to compute estimate
            result = format.formula(_compute)
        }
        if (isNaN(result)) { // if no data is available, use 0
            return 0
        }
        return result
    }

    /* 
        used to render cell based on value entered into it and formatting for that cell
        - props.data = all row data currently rendered
        - props.model = row values entered
        - prop = column name
        - rowIndex = row num
     */
    function cellTemplate(e, props) {
        // grab format for this particular cell
        let rowOffset = 0
        for (let row of grid) {
            if (row._hide) {
                rowOffset++
            }
            if (rowOffset + props.rowIndex === row._index) {
                break
            }
        }
        const currentRow = grid[props.rowIndex + rowOffset]
        const cell = currentRow[props.prop]
        const template = cell.format ? cell.format : {}
        // const template = format[props.model.name]
        const color = template.color || ''
        let colorClass = ''
        let orig = 'data' in cell ? cell.data : cell
        let val = orig
        // let val = props.model[props.prop]

        //  if user didn't define an override, and formula exists for this cell, compute automatically
        if (val === '' && template.formula) {
            // this is both a function and a hash object, so you can call value as rows['key'] to get
            // original or rows('key') to evaluate formula
            val = template.formula(_compute)
        } else if (val[0] === '=') {
            // user formula
            val = evalInContext(val.slice(1), { g: _compute, grid })
        }

        // format to number, make it pretty w/ units
        let formatted = '-'
        let error = null
        if (val && isNaN(val)) {
            formatted = 'ERROR'
            if (typeof val === 'string' && val.startsWith('ERROR:')) {
                error = val.slice(7)
            } else if (typeof orig === 'string' && orig.startsWith('=')) {
                error = 'Invalid formula'
            } else {
                error = 'Not a number'
            }
        } else if (val && template.format) {
            if (template.format.unit === '$') {
                formatted = Number(val).toLocaleString("en-US", { 
                    style: "currency",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    currency: "USD"
                })
            } else if (template.format.unit === '%') {
                formatted = Number(val / 100).toLocaleString("en-US", { 
                    style: "percent",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                })
            } else {
                formatted = Number(val).toLocaleString()
            }

            let gradient = template.format.gradient
            if (gradient) {
                // const val = props.model[props.prop]
                for (let key of Object.keys(gradient)) {
                    if (key.includes('minus') && parseFloat(val) < gradient[key]) {
                        colorClass = key
                        break;
                    } else if (key.includes('plus') && parseFloat(val) > gradient[key]) {
                        colorClass = key
                        break;
                    }
                }
            }
        }
        const element = getCellElement(props)
        return e('div', {
            class: {
                'bubble': color || colorClass ? true : false,
                [color]: true,
                [colorClass]: true
            },
            onmouseover: () => {
                if (error) {
                    pubsub.publish('tooltip:show', { element: element, message: error })
                } else if (typeof orig === 'string' && orig.startsWith('=')) {
                    pubsub.publish('tooltip:show', { element: element, message: orig })
                }
            }
        }, formatted)
    }
    export function getCellElement(detail) {
        const col = Object.keys(detail.model).findIndex((k) => k === detail.prop)
        const row = detail.rowIndex
        const element = document.querySelector(`div[data-rgcol="${col}"][data-rgrow="${row}"]`)
        return element
    }
    columns.forEach(c => {
        if (!c.basic) {
            c.cellTemplate = cellTemplate
        }
    })
</script>

<RevoGrid
    class="grid"
    stretch=false
    on:headerclick
    on:beforeedit={onEdit}
    on:afteredit={normalize}
    on:beforecellfocus
    on:beforefocuslost={onLoseFocus}
    theme='material'
    resize="true"
    {source}
    {columns}
    bind:this={ref}
/>

<style type="text/scss">
    :global(revo-grid) {
      height: 100%;
      font-family: sans-serif;
      text-align: center;
    }
    div {
      font-family: sans-serif;
      text-align: center;
      height: 100%;
    }
    :global(.bubble) {
        // color: #fff;
        border: none;
        height: 32px;
        display: inline-flex;
        outline: 0;
        padding: 0 5px;
        font-size: 0.8125rem;
        box-sizing: border-box;
        transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        align-items: center;
        white-space: nowrap;
        border-radius: 16px;
        text-decoration: none;
        justify-content: end;
        width: 100%;
        margin: 0 -5px;
    }
    :global(.rgCell) {
        text-align: right;
        cursor: text;
        :global(.bubble) {
            border-radius: 10px;
            color: black;
            // opacity: 0.9;
        }
    }
    :global(.rgHeaderCell) {
        text-align: right;
    }
    :global(revogr-edit input) {
        padding: 5px;
    }
    :global(revo-grid[theme=material] revogr-data .rgCell) {
        padding: 0 10px;
    }

    /* these match rank colors */
    // :global(.minus-5) {
    //     background-color: #a50026;
    // }
    // :global(.minus-4) {
    //     background-color: #d73027;
    // }
    // :global(.minus-3) {
    //     background-color: #f46d43;
    // }
    // :global(.minus-2) {
    //     background-color: #fdae61;
    //     color: black;
    // }
    // :global(.minus-1) {
    //     background-color: #fee08b;
    //     color: black;
    // }
    // :global(.plus-1) {
    //     background-color: #d9ef8b;
    //     color: black;
    // }
    // :global(.plus-2) {
    //     background-color: #a6d96a;
    //     color: black;
    // }
    // :global(.plus-3) {
    //     background-color: #66bd63;
    //     color: black;
    // }
    // :global(.plus-4) {
    //     background-color: #1a9850;
    // }
    // :global(.plus-5) {
    //     background-color: #006837;
    // }

    // lighter variants of rank colors, derived using ChatGPT
    :global(.minus-5) {
        background-color: #ea4a6f;
    }
    :global(.minus-4) {
        background-color: #f26c70;
    }
    :global(.minus-3) {
        background-color: #f8937f;
    }
    :global(.minus-2) {
        background-color: #facb95;
    }
    :global(.minus-1) {
        background-color: #fde4b7;
    }
    :global(.plus-1) {
        background-color: #e6f4b9;
    }
    :global(.plus-2) {
        background-color: #c2eaa7;
    }
    :global(.plus-3) {
        background-color: #91dcaa;
    }
    :global(.plus-4) {
        background-color: #65bb94;
    }
    :global(.plus-5) {
        background-color: #38a17a;
    }
</style>